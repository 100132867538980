// import axios, { CancelTokenSource } from "axios";
// import moment from "moment";
import { FC, useEffect, useRef, useState } from "react";
import "react-dates/initialize";
import "react-phone-number-input/style.css";
import { SubmitModal } from "../Modal";
import { GhostBtn, PrimaryBtn } from "../Buttons";
import LoadingContainer from "../LoadingContainer";
import { ModalFormContainer } from "../Modal/styles";
import { FormError, FormInput } from "../FormComponents";
import { countUnresolvedAlerts, postEditAlerts, postEditBulkAlerts } from "../../services/alertNotifications";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import addOrUpdate from "../../util/addOrUpdate";
import { kegOrTracker } from "../../util/kegOrTracker";
// import { toast } from "react-toastify";
// import { ThemeContext } from "styled-components";

const AlertResolutionNotesModal: FC<any> = ({ alert = {}, solveAllForSensor = false, prevResolutionNote, setData, modalOpen, setModalOpen }) => {
  const alertResolutionNoteRef = useRef<HTMLInputElement>(null);

  const [alertResolutionError, setAlertResolutionError] = useState<string>("");
  const [formErrors, setFormErrors] = useState<any>({
    resolutionNote: false,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [count, setCount] = useState<number>(1);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");

  useEffect(() => {
    if (alertResolutionNoteRef.current) {
      alertResolutionNoteRef.current?.focus();
      // alertResolutionNoteRef.current.value = "";
    }
  }, []);
  useEffect(() => {
    if (modalOpen) {
      setAlertResolutionError("");
      setFormErrors({
        resolutionNote: false,
      });
      if (alertResolutionNoteRef.current) {
        alertResolutionNoteRef.current.value = prevResolutionNote || "gege";
      }
    }
  }, [modalOpen, prevResolutionNote]);

  const validateFormBody = (body: any) => {
    if (!body.resolutionNote) {
      setFormErrors((prev: any) => ({ ...prev, resolutionNote: "Please provide a resolution note." }));
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (!solveAllForSensor || !alert.sensorId) {
      return;
    }
    const source = axios.CancelToken.source();

    setLoading(true);
    countUnresolvedAlerts(source, alert.sensorId)
      .then((response) => {
        setCount(response);
        setAlertResolutionError("");
        setLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setAlertResolutionError(errToStr(err));
          setLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, [alert.sensorId || solveAllForSensor]);

  const source = axios.CancelToken.source() as CancelTokenSource;
  const handleSubmit = () => {
    if (!alert.alertId || !alert.sensorId) {
      setAlertResolutionError("Alert object is missing required keys: alertId or sensorId.");
      return;
    }
    const body = {
      action: "resolve",
      resolutionNote: alertResolutionNoteRef.current?.value,
      ...(solveAllForSensor ? { sensorId: alert.sensorId } : { alertId: alert.alertId }),
    };
    const valid = validateFormBody(body);

    if (valid) {
      setSubmitting(true);
      if (solveAllForSensor) {
        postEditBulkAlerts(source, body)
          .then((response: [any]) => {
            response.forEach((alert: any) => {
              setData((prev: []) => addOrUpdate(prev, alert, "alertId"));
            });
            setSubmittedMsg(`All alerts for ${alert.sensorId} were resolved`);
            setSubmitting(false);
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              setSubmittingErr(errToStr(err));
              setSubmitting(false);
            }
          });
        return;
      } else {
        postEditAlerts(source, body)
          .then((response: any) => {
            setData((prev: any) => addOrUpdate(prev, response, "alertId"));
            setSubmittedMsg(prevResolutionNote ? "Note Edited" : "Note Created");
            setSubmitting(false);
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              console.log("valid", body);
              setSubmittingErr(errToStr(err));
              setSubmitting(false);
            }
          });
      }
    }
  };

  return (
    <>
      <SubmitModal
        isOpen={modalOpen}
        onClose={() => {
          if (!submitting) setModalOpen(false);
        }}
        size={!submittedMsg && !submittingErr ? "lg" : "sm"}
        title={"Resolution Notes"}
        success={submittedMsg}
        error={alertResolutionError || submittingErr}
        footer={
          submittedMsg || submittingErr || (solveAllForSensor && count == 0) ? (
            <PrimaryBtn onClick={() => setModalOpen(false)}>Okay</PrimaryBtn>
          ) : (
            <>
              {solveAllForSensor && count > 1 ? (
                <>
                  <GhostBtn onClick={() => setModalOpen(false)}>Leave Unresolved</GhostBtn>
                  <PrimaryBtn onClick={handleSubmit}>{solveAllForSensor ? `Resolve ${count} Alerts` : "Resolve"}</PrimaryBtn>
                </>
              ) : (
                <>
                  <GhostBtn onClick={() => setModalOpen(false)}>Cancel</GhostBtn>
                  <PrimaryBtn onClick={handleSubmit}>Confirm</PrimaryBtn>
                </>
              )}
            </>
          )
        }
        body={
          <LoadingContainer loading={loading || submitting}>
            {solveAllForSensor && count == 0 ? (
              <span>
                All alerts for {kegOrTracker("Keg", "Tracker")} {alert.sensorId} are already resolved.
              </span>
            ) : (
              <>
                <form noValidate onSubmit={(e) => e.preventDefault()}>
                  <ModalFormContainer style={{ padding: "5px 0 19px" }}>
                    Please explain how {count > 1 ? "these alerts were" : "this alert was"} resolved.
                  </ModalFormContainer>
                  <ModalFormContainer>
                    <FormInput type="text" ref={alertResolutionNoteRef} name="alertResolutionNote" />
                    <FormError error={formErrors.resolutionNote}>{formErrors.resolutionNote}</FormError>
                  </ModalFormContainer>
                </form>
              </>
            )}
          </LoadingContainer>
        }
      />
    </>
  );
};

export default AlertResolutionNotesModal;
